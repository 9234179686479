.modal-content-exit {
	position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
	background-color: rgba(0, 0, 0, 0.3);
}

.close-modal-exit {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.close-modal-exit img {
  cursor: pointer;
  margin: 10px;
  width: 50px;
}

.button-exit-exit {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.button-exit-exit button {
  cursor: pointer;
  background-color: #37884e;
  color: white;
  border: none;
  width: 100px;
  height: 50px;
}

.button-exit-exit button:hover {
  opacity: 0.7;
}

.second {
	display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.first{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
  justify-content: center;
  align-items: center;
}

.last-screen{
	position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
	left: 0;
	background-image: url(./fundo.png);
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	
	font-family: Verdana, sans-serif;
}
.container-last{
}
.color-green{
	color: #008c44; 
	font-size: 35px; 
	text-align: center;
}

.weight-light{
	font-weight: lighter;
}

.weight-bold{
	font-weight: bold;
}

.acess-site{
	text-align: center;
}

.acess-site button{
	background-color: #008c44;
	border: none;
	width: 150px;
	height: 40px;
	border-radius: 5px;
	font-weight: bold;
	color: white;
	font-size: 18px;
	cursor: pointer;
	outline: none;
}

.back-site{
	position: absolute;
	bottom: 50px;
	left: 50px;
}

.back-site button{
	background: none;
	display: flex;
	flex-direction: row;
	border: none;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: 18px;
	color: #008c44;
	font-weight: bold;
}

.button-seta{
	margin: 10px;
	width: 75px;
}